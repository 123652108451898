import React from "react";
import { ethers } from "ethers";
import Lottie from "lottie-react";
import { Cookies } from "react-cookie-consent";
import { SocialIcon } from "react-social-icons";
import { ToastContainer } from "react-toastify";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import titleAnimation from "../../animations/network.json";
import {
  CookiesConsentBanner,
  removeAnalyticCookies,
} from "../../components/CookiesConsentBanner";
import WaitlistV0Service from "../../services/WaitlistV0Service";
import "react-toastify/dist/ReactToastify.css";
import { switchNetwork } from "../../utils/network";
import toastUtils from "../../utils/toasts";
import "./index.css";

class App extends React.Component {
  constructor() {
    super();

    this.state = {
      provider: null,
      account: null,
      socialIconStyle: {
        height: 50,
        width: 50,
        margin: "16px",
      },
      titleAnimationStyle: { height: 444, width: 444 },
      hasEnteredWaitlist: false,
      appIsLive: false,
      currentAppBetaLink: "https://goerli.softlink.finance",
    };

    this.WaitlistV0Service = null;

    this.logEvent = this.logEvent.bind(this);
    this.getWeb3 = this.getWeb3.bind(this);
    this.renderCallToAction = this.renderCallToAction.bind(this);
    this.handleEnterWaitlist = this.handleEnterWaitlist.bind(this);
  }

  componentDidMount() {
    const consentCookie = Cookies.get("consent-cookie");
    if (consentCookie && consentCookie === "false") {
      removeAnalyticCookies();
    }
  }

  async logEvent(evt, eventName) {
    try {
      if (evt) evt.preventDefault();
      const address =
        this.state.provider === null
          ? "No provider"
          : await this.state.provider.getSigner().getAddress();
      window.gtag("event", eventName, { address });
    } catch (error) {
      console.log("le error:", error);
    }
  }

  async getWeb3Modal() {
    const network = process.env.CURRENT_ETH_NETWORK || "mainnet";
    const infuraId = process.env.NEXT_PUBLIC_INFURA_ID || "";
    const appName = "SoftLink";
    return new Web3Modal({
      network,
      cacheProvider: false,
      providerOptions: {
        walletconnect: {
          package: WalletConnectProvider,
          options: { infuraId, appName },
        },
        coinbasewallet: {
          package: CoinbaseWalletSDK,
          options: {
            infuraId,
            appName,
          },
        },
        binancechainwallet: {
          package: true,
        },
      },
    });
  }

  async getWeb3(evt, handleEnterWaitlist) {
    try {
      if (evt) evt.preventDefault();

      console.log("connecting to client wallet...");

      const web3Modal = await this.getWeb3Modal();
      const connection = await web3Modal.connect();
      const provider = new ethers.providers.Web3Provider(connection);
      const accounts = await provider.listAccounts();
      const { chainId, name } = await provider.getNetwork();

      // NOTE: Goerli chain for waitlist...
      if (Number(chainId) !== 5) {
        await switchNetwork("0x5");
        return this.getWeb3(evt, handleEnterWaitlist);
      }

      this.WaitlistV0Service = new WaitlistV0Service(provider);

      this.setState(
        {
          provider,
          chainId,
          chainName: name,
          account: accounts[0],
        },
        async () => {
          if (handleEnterWaitlist) {
            await this.handleEnterWaitlist(evt);
          }
        }
      );
    } catch (error) {
      console.log("getWeb3() error:", error);
      toastUtils.displayToastMessage(
        "error",
        "Connection failure: Please try again"
      );
    }
  }

  renderHeader() {
    return (
      <header className="row">
        <div className="header-brand-name-container">
          <h1>SoftLink</h1>
        </div>
      </header>
    );
  }

  renderWhitepaperDownloadButton() {
    return (
      <a
        onClick={(evt) => this.logEvent(undefined, "download_whitepaper")}
        className="whitepaper-action-link"
        href="/whitepaper.pdf"
        target="_blank"
        rel="noreferrer"
      >
        <button className="action-button">Whitepaper</button>
      </a>
    );
  }

  async handleEnterWaitlist(evt) {
    try {
      if (evt) evt.preventDefault();

      if (this.state.hasEnteredWaitlist) {
        return window.open(this.state.currentAppBetaLink, "_blank");
      }

      const hasEntered = await this.WaitlistV0Service.hasEnteredWaitlist();
      if (hasEntered) {
        return this.setState({
          hasEnteredWaitlist: true,
        });
      }

      this.logEvent(undefined, "join_waitlist");

      toastUtils.displayToastMessage("info", "Submitting transaction...");

      await this.WaitlistV0Service.joinWailist();

      return this.setState(
        {
          hasEnteredWaitlist: true,
        },
        () => {
          toastUtils.displayToastMessage(
            "success",
            "You're on the waitlist :D"
          );
        }
      );
    } catch (error) {
      console.log("handleEnterWaitlist error:", error);
      toastUtils.displayToastMessage(
        "error",
        "Failed to join...check developer logs and/or try again"
      );
    }
  }

  renderCallToAction() {
    if (this.state.appIsLive) {
      return (
        <a href="https://app.softlink.finance">
          <button className="action-button">Enter app</button>
        </a>
      );
    }
    let action =
      this.state.provider === null
        ? (evt) => this.getWeb3(evt, true)
        : this.handleEnterWaitlist;
    let label = this.state.hasEnteredWaitlist ? "Enter Beta" : "Join Waitlist";
    return (
      <button className="action-button" onClick={action}>
        {label}
      </button>
    );
  }

  renderMainContent() {
    return (
      <div className="main-content-container row">
        <div className="title-container row">
          <div className="descriptions-container col-12 col-lg-6">
            <h1>Profit from successful MEV strategies</h1>
            <h2>
              SoftLink is a platform that links together a network of Liquidity
              Providers and Searchers to benefit from successful MEV strategies
              executed on an EVM-compatible network via smart liquidity
              management of reserves.
              <br />
              <br />
              <strong>
                Connect to{" "}
                <a
                  id="goerli-faucet-link"
                  href="https://goerli-faucet.pk910.de/"
                  target="_blank"
                  rel="noreferrer"
                >
                  Goerli
                </a>{" "}
                (
                <a
                  id="goerli-faucet-link"
                  href="https://blog.ethereum.org/2022/06/21/testnet-deprecation"
                  target="_blank"
                  rel="noreferrer"
                >
                  Why?
                </a>
                ) to join for free :)
              </strong>
            </h2>
            <div className="whitepaper-cta-container">
              {this.renderWhitepaperDownloadButton()}
              {this.renderCallToAction()}
            </div>
          </div>
          <div className="title-animation-container col-12 col-lg-6">
            <Lottie
              animationData={titleAnimation}
              loop={true}
              style={this.state.titleAnimationStyle}
            />
          </div>
        </div>
      </div>
    );
  }

  renderFooter() {
    return (
      <footer>
        <SocialIcon
          onClick={(evt) => this.logEvent(undefined, "view_github")}
          url="https://github.com/softlink-protocol"
          style={this.state.socialIconStyle}
          bgColor="#FFFFFF"
          fgColor="#000000"
        />
        <SocialIcon
          onClick={(evt) => this.logEvent(undefined, "view_medium")}
          url="https://medium.com/@softlinkfinance"
          style={this.state.socialIconStyle}
          bgColor="#FFFFFF"
          fgColor="#000000"
        />
        <SocialIcon
          onClick={(evt) => this.logEvent(undefined, "view_twitter")}
          url="https://twitter.com/softlinkfinance"
          style={this.state.socialIconStyle}
          bgColor="#FFFFFF"
          fgColor="#000000"
        />
      </footer>
    );
  }

  render() {
    return (
      <div className="main-container">
        {this.renderHeader()}
        {this.renderMainContent()}
        {this.renderFooter()}
        <CookiesConsentBanner />
        <ToastContainer />
      </div>
    );
  }
}

export default App;
