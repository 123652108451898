import { ethers } from "ethers";
import { waitlistV0Address } from "../addresses";
import WaitlistV0 from "../../contracts/WaitlistV0.json";

class WaitlistV0Service {
  constructor(provider) {
    this.provider = provider;
    this.reserveAddress = waitlistV0Address;
    this.contract = new ethers.Contract(
      waitlistV0Address,
      WaitlistV0.abi,
      this.provider.getSigner()
    );
  }

  async joinWailist() {
    const tx = await this.contract.join();
    await tx.wait();
  }

  async hasEnteredWaitlist() {
    return await this.contract.hasEntered(
      this.provider.getSigner().getAddress()
    );
  }
}

export default WaitlistV0Service;
