import CookieConsent from "react-cookie-consent";

export const removeAnalyticCookies = () => {
  if (window.ga) window.ga("remove");
  if (window.gtag) {
    window.gtag("consent", "default", {
      ad_storage: "denied",
      analytics_storage: "denied",
    });
    window.gtag = null;
  }
};

export const CookiesConsentBanner = (props) => {
  return (
    <CookieConsent
      location="bottom"
      buttonText="I accept"
      cookieName="consent-cookie"
      style={{ background: "black" }}
      buttonStyle={{ color: "#4e503b", fontSize: "15px" }}
      expires={180}
      enableDeclineButton
      onDecline={removeAnalyticCookies}
    >
      SoftLink uses cookies for this page to learn more about those who are
      interested in the protocol. By visiting our page you consent to all
      cookies in accordance with our{" "}
      <a href={process.env.PUBLIC_URL + "/privacy-policy.txt"}>
        Privacy Policy
      </a>
    </CookieConsent>
  );
};
